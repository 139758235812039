<template>
  <b-row>
    <b-col
      cols="12"
      offset="0"
      lg="8"
      offset-lg="2"
      xl="6"
      offset-xl="3"
      class="p-sm-0"
    >
      <b-col cols="12"><hr /></b-col>
      <b-col class="form-group" cols="12">
        <b-row>
          <b-col cols="auto">
            <label style="color: #83bf6f; font-size: 20px"
              >Please upload your CV</label
            ></b-col
          >
          <b-col cols="12">
            <b-form-file
              accept="application/pdf,.docx"
              @change="m_onChangeCV"
              v-model="d_cvInput"
              :placeholder="
                gt_user.additional.cv
                  ? co_getCVLabel
                  : 'Choose a file or drop it here...'
              "
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <strong v-if="d_sizeLimitReached"
              ><label style="margin: 50px; color: #f33066; font-size: 20px"
                >CV size must not exceed 1MB</label
              ></strong
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="form-group text-center"
        cols="12"
        v-if="gt_user && gt_user.additional"
      >
        <div class="row">
          <div class="col"><hr /></div>
          <div
            class="col-auto"
            v-show="
              gt_user.additional &&
              gt_user.additional.cv &&
              gt_user.additional.cv != ''
            "
          >
            <a
              onclick="this.blur();"
              :href="gt_user.additional.cv"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-light"
              >Preview my CV</a
            >
          </div>
          <div class="col"><hr /></div>
        </div>
        <cmp-required
          v-if="!gt_user.additional.cv && !gt_user.additional.cv != ''"
          ref="ref_cv"
          :p_value="d_cvInput"
          :p_validations="['file']"
        />
      </b-col>

      <b-col cols="12" v-else><hr /></b-col>
    </b-col>
  </b-row>
</template>

<script>
import c_required from "./cmp-required.vue";
import { mapGetters } from "vuex";
import { fb_cvStorageref, fb_tutorsCollection } from "@/lib-core/lib-firebase";
export default {
  name: "cmp-cv",
  components: {
    "cmp-required": c_required,
  },
  computed: {
    ...mapGetters("md_auth", ["gt_authUser"]),
    ...mapGetters("md_fire", ["gt_user"]),
    co_getCVLabel() {
      const l_cvname = this.gt_user.additional.cvName;
      const l_cvdate = this.gt_user.additional.cvDate;
      let l_cv = "";
      if (l_cvname) l_cv += l_cvname;
      if (l_cvdate) l_cv += " ~ Uploaded on " + this.toDate(l_cvdate);
      return l_cv;
    },
  },
  async mounted() {},
  data() {
    return {
      d_cvInput: null,
      d_cvUpdate: false,
      d_sizeLimitReached: false,
    };
  },
  methods: {
    m_isValid() {
      return !this.$refs.ref_cv || this.$refs.ref_cv.m_isValid();
    },
    m_onChangeCV(event) {
      let file = event.target.files[0];
      if (file) this.$emit("e_CVChanged", file.name);
      else this.$emit("e_CVChanged", "");
    },
    toDate(timestamp) {
      return this.$moment(timestamp.toDate()).format("Do [of] MMMM YYYY");
    },
    m_updateMyCV() {
      this.d_cvUpdate = true;
    },
    m_isValidSize(p_file) {
      var l_fileSize = p_file.size / 1024 / 1024; // in MB
      return l_fileSize < 1;
    },
    async m_uploadcv() {
      if (this.d_cvInput == null) return false;

      if (!this.m_isValidSize(this.d_cvInput)) {
        // console.log("the CV must not exceed 1mb");
        this.d_sizeLimitReached = true;
        return false;
      }
      this.d_sizeLimitReached = false;
      var l_blob = this.d_cvInput.slice(
        0,
        this.d_cvInput.size,
       this.d_cvInput.type
      );
      var l_file = new File([l_blob], `${this.gt_authUser.email}.${this.d_cvInput.name.split('.')[1]}`, {
        type: this.d_cvInput.type,
      });
      const l_cvRef = fb_cvStorageref.child(`${this.gt_authUser.email}.${this.d_cvInput.name.split('.')[1]}`);
      try {
        const p_snapshot = await l_cvRef.put(l_file);
        const p_downloadURL = await l_cvRef.getDownloadURL();
        await fb_tutorsCollection.doc(this.gt_authUser.email).set(
          {
            additional: {
              cv: p_downloadURL,
              cvName: this.d_cvInput.name.slice(0, 50),
              cvDate: this.d_cvInput.lastModifiedDate,
            },
          },
          { merge: true }
        );
        this.d_cvUpdate = false;
        return true;
      } catch (p_error) {
        switch (p_error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }
    },
  },
  watch: {
    d_cvInput(newFile) {
      if (newFile && !newFile.type.match("application/pdf") && 
          !newFile.type.match("application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
          !this.m_isValidSize(newFile) ) {
        let l_payload = {
          message: "Please upload a PDF or Word document less than 1 Mb in size",
          button: {
            yes: "Ok",
          },
        };
        this.$confirm(l_payload);
        this.$nextTick(() => {
          this.d_cvInput = null;
        });
      }
    },
  },
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid #eee;
}
</style>
<style>
.custom-file-label {
  background-color: #fff;
  border: 1px solid #82bf6f;
}
.custom-file-label::after {
  color: #ffffff;
  background-color: #82bf6f;
}
</style>