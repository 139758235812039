<template>
  <span
    class="text-danger"
    style="min-height: 22px; margin-right: 5px; float: right; margin-top: 4px"
    >{{ d_error }}</span
  >
</template>

<script>
export default {
  name: "cmp-required",
  props: {
    p_validations: {
      type: Array,
    },
    p_value: {},
  },
  data() {
    return {
      d_error: null,
      d_step: -1,
    };
  },
  watch: {
    p_value: function (p_new) {
      this.d_step++;
      this.m_check(p_new);
    },
  },
  methods: {
    m_isValid() {
      return this.m_check(this.p_value, true);
    },
    m_reset() {
      this.d_error = null;
    },
    m_check(p_value, p_skip) {
      this.d_error = null;

      if (!p_skip && this.d_step == 0) return true;

      if (!this.p_validations || this.p_validations.length == 0) {
        return true;
      }
      for (let l_index = 0; l_index < this.p_validations.length; l_index++) {
        this.m_checkSingleValidation(this.p_validations[l_index], p_value);
        if (this.d_error) return false;
      }
      return true;
    },
    m_checkSingleValidation(p_validation, p_value) {
      switch (p_validation) {
        case "required":
          if (this.m_isNotEmpty(p_value)) return;
          this.d_error = "Required";
          break;
        case "minimum-3":
          if (this.m_isAboveMinimum(p_value, 3)) return;
          this.d_error = "Invalid";
          break;
        case "alphabet":
          if (this.m_isAlphabetOnly(p_value)) return;
          this.d_error = "Invalid";
          break;
        case "file":
          if (
            !this.p_value ||
            !this.p_value.name ||
            this.p_value.name.trim().length == 0
          )
            this.d_error = "Required";
          break;
      }
    },
    m_isNotEmpty(p_field) {
      return this.m_isAboveMinimum(p_field, 1);
    },
    m_isAboveMinimum(p_field, p_minimum) {
      return p_field && p_field.trim().length >= p_minimum;
    },
    m_isAlphabetOnly(p_field) {
      return /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(p_field);
    },
  },
};
</script>

<style>
</style>