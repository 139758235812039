<template>
  <div class="row" v-if="!d_loading">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div
        class="dashboard-container"
        style="padding-bottom: 0px; margin-bottom: 0px"
      >
        <!-- <div class="dashboard-container-header">
          <div class="dashboard-fl-1">
            <h4>Account information</h4>
          </div>
        </div> -->
        <div class="dashboard-container-body p-4">
          <!-- Basic info -->
          <div class="submit-section">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Full Name*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="d_displayName"
                />
                <cmp-required
                  ref="ref_fullname"
                  :p_value="d_displayName"
                  :p_validations="['required', 'alphabet']"
                />
              </div>
              <div class="form-group col-md-6" v-if="gt_authUser">
                <label>Email*</label>
                <input
                  type="email"
                  class="form-control"
                  disabled
                  :value="gt_user.account.email"
                />
              </div>

              <cmp-nationality
                ref="ref_nationality"
                :p_nationality="d_nationality"
                @e_change="m_onChangeNationality"
              />

              <div class="form-group col-md-6">
                <label>Phone*</label>
                <VuePhoneNumberInput
                  style="height: 100vh"
                  v-model="d_phone"
                  @update="m_onPhoneChanged"
                  :fetch-country="d_fetchPhoneCountry"
                  valid-color="#e6eaf3"
                  :default-country-code="d_countrycode"
                />
                <cmp-required
                  ref="ref_phone"
                  :p_value="d_phone"
                  :p_validations="['required']"
                />
              </div>

              <div class="form-group col-md-6">
                <label>Address*</label>
                <input v-model="d_address" type="text" class="form-control" />
                <cmp-required
                  ref="ref_address"
                  :p_value="d_address"
                  :p_validations="['required', 'minimum-3']"
                />
              </div>

              <div class="form-group col-md-6">
                <label>Languages*</label>
                <input
                  v-model="d_lang"
                  type="text"
                  class="form-control"
                  placeholder="ex: ar, en"
                />
                <cmp-required
                  ref="ref_lang"
                  :p_value="d_lang"
                  :p_validations="['required']"
                />
              </div>
              <div class="form-group col-md-6">
                <label>Date of birth*</label>
                <input
                  v-model="d_dob"
                  type="date"
                  class="form-control"
                  placeholder="dd-mm-yyyy"
                  min="1920-01-01"
                  max="2020-12-31"
                />
                <cmp-required
                  ref="ref_dob"
                  :p_value="d_dob"
                  :p_validations="['required']"
                />
              </div>

              <div class="form-group col-md-6">
                <label style="margin-bottom: 20px">Gender*</label><br />

                <input
                  v-model="d_gender"
                  type="radio"
                  class="form-control"
                  id="male"
                  name="gender"
                  value="M"
                />
                <label for="male" style="padding-right: 20px">Male</label>
                <input
                  v-model="d_gender"
                  type="radio"
                  class="form-control"
                  id="female"
                  name="gender"
                  value="F"
                />
                <label for="female">Female</label><br />
                <cmp-required
                  ref="ref_gender"
                  :p_value="d_gender"
                  :p_validations="['required']"
                />
              </div>
              <div class="form-group col-md-12">
                <label>Bio*</label>
                <textarea v-model="d_bio" class="form-control"></textarea>
                <cmp-required
                  ref="ref_bio"
                  :p_value="d_bio"
                  :p_validations="['required']"
                />
              </div>
              <div
                class="form-group col-md-12"
                v-if="
                  gt_user && gt_user.account && gt_user.account.role == 'tutor'
                "
              >
                <cmp-cv ref="ref_cv" @e_CVChanged="m_getCVFromComponent" />
              </div>
            </div>
          </div>
          <!-- Basic info -->
          <div class="form-group text-right col-lg-12 col-md-12">
            <button
              @click="m_saveChanges"
              class="btn btn-theme"
              type="submit"
              style="width: 100%"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
		<b-modal v-model="d_showTermsModal" id="mm" scrollable title="Terms and Conditions">
    <p class="my-4" v-html="this.gt_user.account.role == 'tutor'?d_tutorsTerms:d_studenstTerms">
      
    </p>
		<template #modal-footer>
        <div class="w-100 text-center">
					<button
               @click="m_acceptTerms"
              class="btn btn-theme ">
             I Accept Terms and conditions 
            </button>
        </div>
      </template>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import c_nationality from "../cmp-components/cmp-nationality.vue";
import {
  fb_tutorsCollection,
  fb_studentsCollection,
  fb_Timestamp,
} from "@/lib-core/lib-firebase";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import c_cv from "../cmp-components/cmp-cv.vue";
import c_required from "../cmp-components/cmp-required.vue";
export default {
  name: "cmp-account",
  components: {
    "cmp-nationality": c_nationality,
    VuePhoneNumberInput,
    "cmp-cv": c_cv,
    "cmp-required": c_required,
  },
  data() {
    return {
      d_dob: "",
      d_gender: null,
      d_nationality: "",
      d_bio: "",
      d_phone: "",
      d_countrycode: null,
      d_countrycallingCode: null,
      d_fetchPhoneCountry: false,
      d_lang: "",
      d_address: "",
      d_displayName: "",
      d_loading: true,
      d_cv: "",
      d_isCreateProfile: null,
			d_tutorsTerms:`By submitting your application, you acknowledge that:<br>
- You will not be able to deal directly with any student booked via 971tutors, directly without the approval of 971tutors for the 12 months following the last delivered session with that student. Else, this will be a breach of your obligation towards 971tutors and 971tutors will have the right to legally take actions or block you form the platform.<br> 
- You will be responsible for your behavior during the sessions with the students. Any wrong behavior will be solely your responsibility.<br>
- You acknowledge that you are working with 971tutors as a freelancer, and the only financial obligation from 971tutors towards you are the payments related to delivered sessions.<br>
- payments to teachers will be done either mid of the month or end of the month for delivered sessions. Hence the teacher will have to request the student acceptance on the web site for each session delivered immediately after the session is completed for payment to be made.<br>`,
			d_studenstTerms:`By singing in as a student on the 971tutors.com platform you acknowledge the following:<br>
- You cannot deal directly with any teacher booked via 971tutors without the approval of 971tutors for the 12months following the last session with that teacher.<br> 
- You will notify 971tutors immediately for any wrong behavior of any of our teachers. We do the right diligence for our teachers and won't accept any wrong behavior from our teachers during their sessions.<br>`,
    d_showTermsModal:false,
		d_acceptedTerms:false
		};
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user"]),
    ...mapGetters("md_auth", ["gt_authUser"]),
  },
  mounted() {
    this.$store.commit("md_fire/mt_setDashboardSection", "account");
    setTimeout(() => {
      if (!this.gt_authUser || !this.gt_authUser.emailVerified) {
        this.$router.push({ name: "verify" });
        return;
      }
      this.m_fillData();
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1000);
  },
  methods: {
    m_getCVFromComponent(p_filename) {
      if (p_filename) {
        this.d_cv = p_filename;
      } else if (
        this.gt_user &&
        this.gt_user.additional &&
        this.gt_user.additional.cvName
      ) {
        this.d_cv = this.gt_user.additional.cvName;
      } else {
        this.d_cv = "";
      }
    },
    m_fillData() {
      if (!this.gt_user) return;
      if (this.gt_user.account != null ) {
        if (
          this.gt_user.account.dob &&
          this.$moment(this.gt_user.account.dob.toDate()).format(
            "YYYY-MM-DD"
          ) != this.$moment(new Date("0001-01-01")).format("YYYY-MM-DD")
        ) {
          this.d_dob = this.$moment(this.gt_user.account.dob.toDate()).format(
            "YYYY-MM-DD"
          );
        }
        if (this.gt_user.account.gender) {
          this.d_gender = this.gt_user.account.gender;
        }
        if (this.gt_user.account.nationality) {
          this.d_nationality = this.gt_user.account.nationality;
        }
        if (this.gt_user.account.bio) {
          this.d_bio = this.gt_user.account.bio;
        }
        if (
          !this.gt_user.account.phone ||
          this.gt_user.account.phone.length == 0
        ) {
          this.d_countrycode = null;
          this.d_fetchPhoneCountry = true;
        } else {
          this.d_countrycode = this.gt_user.account.countrycode;
          this.d_phone = this.gt_user.account.phone;
          this.d_countrycallingCode = this.gt_user.account.countryCallingCode;
        }
        if (this.gt_user.account.lang) {
          this.d_lang = this.gt_user.account.lang;
        }
        if (this.gt_user.account.address) {
          this.d_address = this.gt_user.account.address;
        }
        if (this.gt_user.account.displayName) {
          this.d_displayName = this.gt_user.account.displayName;
        }
      }
      if (this.gt_user.additional && this.gt_user.additional.cvName) {
        this.d_cv = this.gt_user.additional.cvName;
      }
      this.d_loading = false;
    },
    m_isCreateProfile() {
      this.d_isCreateProfile =
        !this.gt_user ||
        !this.gt_user.account ||
        !this.gt_user.account.gender ||
				this.gt_user.account.gender=="" ||
        !this.gt_user.account.address||
				this.gt_user.account.address==""
    },
    m_autoNavigateToApply() {
      if (this.gt_user.account.role == "student") {
        if (this.d_isCreateProfile) {
          this.$router.push({ name: "education" });
        }
        return;
      }

      if (
        this.d_isCreateProfile ||
        !this.$store.state.md_fire.st_tutorSubjects ||
        this.$store.state.md_fire.st_tutorSubjects.length == 0
      ) {
        this.$router.push({ name: "tutoringsubjects" });
      }
    },
    m_onPhoneChanged(p_valobj) {
      this.d_countrycode = p_valobj.countryCode;
      this.d_countrycallingCode = p_valobj.countryCallingCode;
    },
    m_onChangeNationality(p_value) {
      this.d_nationality = p_value;
    },
    m_saveChanges() {
      if (!this.m_isRequiredFieldsFilled(this.gt_user.account.role)) return;
			this.m_isCreateProfile();
			if(this.d_isCreateProfile&&!this.d_acceptedTerms)
			{
				this.d_showTermsModal=true;
				return;
			}
      this.m_proceedSaveChanges();
    },
    async m_proceedSaveChanges() {
      this.$store.commit("md_fire/mt_setLoading", true);
      let l_payload = {
        ...this.gt_user,
        ...{
          account: {
            // d_dob: l_newDOB,
            displayName: this.d_displayName,
            phone: this.d_phone,
            countrycode: this.d_countrycode || "",
            countryCallingCode: this.d_countrycallingCode || "",
            address: this.d_address,
            lang: this.d_lang,
            gender: this.d_gender,
            nationality: this.d_nationality,
            bio: this.d_bio,
          },
        },
      };

      try {
        if (this.d_dob) {
          const l_newDOB = fb_Timestamp.fromDate(new Date(this.d_dob));
          l_payload.account.dob = l_newDOB;
        } else {
          l_payload.account.dob = fb_Timestamp.fromDate(new Date("0001-01-01"));
        }
      } catch (error) {
        //console.log("Error with DOB");
      }
      const l_collection =
        this.gt_user.account.role == "tutor"
          ? fb_tutorsCollection
          : fb_studentsCollection;
      await l_collection
        .doc(this.gt_user.account.email)
        .set(l_payload, { merge: true });

      if (this.gt_user.account.role == "tutor")
        await this.$refs.ref_cv.m_uploadcv();

      this.m_profileSavedPopup();
    },
    async m_profileSavedPopup() {
      await this.$confirm({
        title: `Profile ${this.d_isCreateProfile ? "created" : "updated"}!`,
        button: {
          yes: "Ok",
        },
        callback: this.m_autoNavigateToApply,
      });
      this.$store.commit("md_fire/mt_setLoading", false);
      return true;
    },
    m_isRequiredFieldsFilled(p_role) {
      let l_isValid =
        this.$refs.ref_fullname.m_isValid() &
        this.$refs.ref_gender.m_isValid() &
        this.$refs.ref_nationality.m_isValid() &
        this.$refs.ref_lang.m_isValid() &
        this.$refs.ref_bio.m_isValid() &
        this.$refs.ref_phone.m_isValid() &
        this.$refs.ref_address.m_isValid() &
        this.$refs.ref_dob.m_isValid();

      if (p_role == "tutor")
        l_isValid = l_isValid & this.$refs.ref_cv.m_isValid();

      if (!l_isValid) {
        this.$confirm({
          message: "Please fill all the required fields to continue",
          button: {
            yes: "Ok",
          },
        });
      }
      return l_isValid;
    },
		m_acceptTerms()
		{
			this.d_showTermsModal=false;
			this.d_acceptedTerms=true;
			this.m_saveChanges();
		}
  },
};
</script>

<style>
.input-tel__input {
  background: #f4f5f7;
  height: 54px !important;
}
.country-selector__input {
  background: #f4f5f7;
  height: 54px !important;
}
#MazPhoneNumberInput-32_country_selector {
  background: #f4f5f7;
  height: 54px !important;
}
.iti-flag-small {
  margin-top: 8px;
}
#MazPhoneNumberInput-32_phone_number {
  background: #f4f5f7;
  height: 54px !important;
}

.vue-phone-number-input {
  height: 54px !important;
}
.input-tel[data-v-e59be3b4] {
  height: 54px !important;
}
.input-country-selector {
  height: 54px !important;
}
/* .form-control {
  height: 40px;
} */

textarea.form-control {
  height: 140px !important;
}
</style>