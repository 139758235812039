<template>
  <div class="form-group col-md-6">
    <label>Nationality*</label>
    <select
      style="height: 54px"
      v-model="d_value"
      name="nationality"
      class="form-control"
      @change="m_onChangeNationality"
    >
      <option class="dropdown-item" value="">-- select one --</option>
      <option class="dropdown-item" value="afghan">Afghan</option>
      <option class="dropdown-item" value="albanian">Albanian</option>
      <option class="dropdown-item" value="algerian">Algerian</option>
      <option class="dropdown-item" value="american">American</option>
      <option class="dropdown-item" value="andorran">Andorran</option>
      <option class="dropdown-item" value="angolan">Angolan</option>
      <option class="dropdown-item" value="antiguans">Antiguans</option>
      <option class="dropdown-item" value="argentinean">Argentinean</option>
      <option class="dropdown-item" value="armenian">Armenian</option>
      <option class="dropdown-item" value="australian">Australian</option>
      <option class="dropdown-item" value="austrian">Austrian</option>
      <option class="dropdown-item" value="azerbaijani">Azerbaijani</option>
      <option class="dropdown-item" value="bahamian">Bahamian</option>
      <option class="dropdown-item" value="bahraini">Bahraini</option>
      <option class="dropdown-item" value="bangladeshi">Bangladeshi</option>
      <option class="dropdown-item" value="barbadian">Barbadian</option>
      <option class="dropdown-item" value="barbudans">Barbudans</option>
      <option class="dropdown-item" value="batswana">Batswana</option>
      <option class="dropdown-item" value="belarusian">Belarusian</option>
      <option class="dropdown-item" value="belgian">Belgian</option>
      <option class="dropdown-item" value="belizean">Belizean</option>
      <option class="dropdown-item" value="beninese">Beninese</option>
      <option class="dropdown-item" value="bhutanese">Bhutanese</option>
      <option class="dropdown-item" value="bolivian">Bolivian</option>
      <option class="dropdown-item" value="bosnian">Bosnian</option>
      <option class="dropdown-item" value="brazilian">Brazilian</option>
      <option class="dropdown-item" value="british">British</option>
      <option class="dropdown-item" value="bruneian">Bruneian</option>
      <option class="dropdown-item" value="bulgarian">Bulgarian</option>
      <option class="dropdown-item" value="burkinabe">Burkinabe</option>
      <option class="dropdown-item" value="burmese">Burmese</option>
      <option class="dropdown-item" value="burundian">Burundian</option>
      <option class="dropdown-item" value="cambodian">Cambodian</option>
      <option class="dropdown-item" value="cameroonian">Cameroonian</option>
      <option class="dropdown-item" value="canadian">Canadian</option>
      <option class="dropdown-item" value="cape verdean">Cape Verdean</option>
      <option class="dropdown-item" value="central african">
        Central African
      </option>
      <option class="dropdown-item" value="chadian">Chadian</option>
      <option class="dropdown-item" value="chilean">Chilean</option>
      <option class="dropdown-item" value="chinese">Chinese</option>
      <option class="dropdown-item" value="colombian">Colombian</option>
      <option class="dropdown-item" value="comoran">Comoran</option>
      <option class="dropdown-item" value="congolese">Congolese</option>
      <option class="dropdown-item" value="costa rican">Costa Rican</option>
      <option class="dropdown-item" value="croatian">Croatian</option>
      <option class="dropdown-item" value="cuban">Cuban</option>
      <option class="dropdown-item" value="cypriot">Cypriot</option>
      <option class="dropdown-item" value="czech">Czech</option>
      <option class="dropdown-item" value="danish">Danish</option>
      <option class="dropdown-item" value="djibouti">Djibouti</option>
      <option class="dropdown-item" value="dominican">Dominican</option>
      <option class="dropdown-item" value="dutch">Dutch</option>
      <option class="dropdown-item" value="east timorese">East Timorese</option>
      <option class="dropdown-item" value="ecuadorean">Ecuadorean</option>
      <option class="dropdown-item" value="egyptian">Egyptian</option>
      <option class="dropdown-item" value="emirian">Emirian</option>
      <option class="dropdown-item" value="equatorial guinean">
        Equatorial Guinean
      </option>
      <option class="dropdown-item" value="eritrean">Eritrean</option>
      <option class="dropdown-item" value="estonian">Estonian</option>
      <option class="dropdown-item" value="ethiopian">Ethiopian</option>
      <option class="dropdown-item" value="fijian">Fijian</option>
      <option class="dropdown-item" value="filipino">Filipino</option>
      <option class="dropdown-item" value="finnish">Finnish</option>
      <option class="dropdown-item" value="french">French</option>
      <option class="dropdown-item" value="gabonese">Gabonese</option>
      <option class="dropdown-item" value="gambian">Gambian</option>
      <option class="dropdown-item" value="georgian">Georgian</option>
      <option class="dropdown-item" value="german">German</option>
      <option class="dropdown-item" value="ghanaian">Ghanaian</option>
      <option class="dropdown-item" value="greek">Greek</option>
      <option class="dropdown-item" value="grenadian">Grenadian</option>
      <option class="dropdown-item" value="guatemalan">Guatemalan</option>
      <option class="dropdown-item" value="guinea-bissauan">
        Guinea-Bissau
      </option>
      <option class="dropdown-item" value="guinean">Guinean</option>
      <option class="dropdown-item" value="guyanese">Guyanese</option>
      <option class="dropdown-item" value="haitian">Haitian</option>
      <option class="dropdown-item" value="herzegovinian">Herzegovinian</option>
      <option class="dropdown-item" value="honduran">Honduran</option>
      <option class="dropdown-item" value="hungarian">Hungarian</option>
      <option class="dropdown-item" value="icelander">Icelander</option>
      <option class="dropdown-item" value="indian">Indian</option>
      <option class="dropdown-item" value="indonesian">Indonesian</option>
      <option class="dropdown-item" value="iranian">Iranian</option>
      <option class="dropdown-item" value="iraqi">Iraqi</option>
      <option class="dropdown-item" value="irish">Irish</option>
      <option class="dropdown-item" value="israeli">Israeli</option>
      <option class="dropdown-item" value="italian">Italian</option>
      <option class="dropdown-item" value="ivorian">Ivorian</option>
      <option class="dropdown-item" value="jamaican">Jamaican</option>
      <option class="dropdown-item" value="japanese">Japanese</option>
      <option class="dropdown-item" value="jordanian">Jordanian</option>
      <option class="dropdown-item" value="kazakhstani">Kazakhstani</option>
      <option class="dropdown-item" value="kenyan">Kenyan</option>
      <option class="dropdown-item" value="kittian and nevisian">
        Kittitian and Nevisian
      </option>
      <option class="dropdown-item" value="kuwaiti">Kuwaiti</option>
      <option class="dropdown-item" value="kyrgyz">Kyrgyz</option>
      <option class="dropdown-item" value="laotian">Laotian</option>
      <option class="dropdown-item" value="latvian">Latvian</option>
      <option class="dropdown-item" value="lebanese">Lebanese</option>
      <option class="dropdown-item" value="liberian">Liberian</option>
      <option class="dropdown-item" value="libyan">Libyan</option>
      <option class="dropdown-item" value="liechtensteiner">
        Liechtensteiner
      </option>
      <option class="dropdown-item" value="lithuanian">Lithuanian</option>
      <option class="dropdown-item" value="luxembourger">Luxembourger</option>
      <option class="dropdown-item" value="macedonian">Macedonian</option>
      <option class="dropdown-item" value="malagasy">Malagasy</option>
      <option class="dropdown-item" value="malawian">Malawian</option>
      <option class="dropdown-item" value="malaysian">Malaysian</option>
      <option class="dropdown-item" value="maldivan">Maldivan</option>
      <option class="dropdown-item" value="malian">Malian</option>
      <option class="dropdown-item" value="maltese">Maltese</option>
      <option class="dropdown-item" value="marshallese">Marshallese</option>
      <option class="dropdown-item" value="mauritanian">Mauritanian</option>
      <option class="dropdown-item" value="mauritian">Mauritian</option>
      <option class="dropdown-item" value="mexican">Mexican</option>
      <option class="dropdown-item" value="micronesian">Micronesian</option>
      <option class="dropdown-item" value="moldovan">Moldovan</option>
      <option class="dropdown-item" value="monacan">Monacan</option>
      <option class="dropdown-item" value="mongolian">Mongolian</option>
      <option class="dropdown-item" value="moroccan">Moroccan</option>
      <option class="dropdown-item" value="mosotho">Mosotho</option>
      <option class="dropdown-item" value="motswana">Motswana</option>
      <option class="dropdown-item" value="mozambican">Mozambican</option>
      <option class="dropdown-item" value="namibian">Namibian</option>
      <option class="dropdown-item" value="nauruan">Nauruan</option>
      <option class="dropdown-item" value="nepalese">Nepalese</option>
      <option class="dropdown-item" value="new zealander">New Zealander</option>
      <option class="dropdown-item" value="ni-vanuatu">Ni-Vanuatu</option>
      <option class="dropdown-item" value="nicaraguan">Nicaraguan</option>
      <option class="dropdown-item" value="nigerien">Nigerien</option>
      <option class="dropdown-item" value="north korean">North Korean</option>
      <option class="dropdown-item" value="northern irish">
        Northern Irish
      </option>
      <option class="dropdown-item" value="norwegian">Norwegian</option>
      <option class="dropdown-item" value="omani">Omani</option>
      <option class="dropdown-item" value="pakistani">Pakistani</option>
      <option class="dropdown-item" value="palauan">Palauan</option>
      <option class="dropdown-item" value="panamanian">Panamanian</option>
      <option class="dropdown-item" value="papua new guinean">
        Papua New Guinean
      </option>
      <option class="dropdown-item" value="paraguayan">Paraguayan</option>
      <option class="dropdown-item" value="peruvian">Peruvian</option>
      <option class="dropdown-item" value="polish">Polish</option>
      <option class="dropdown-item" value="portuguese">Portuguese</option>
      <option class="dropdown-item" value="qatari">Qatari</option>
      <option class="dropdown-item" value="romanian">Romanian</option>
      <option class="dropdown-item" value="russian">Russian</option>
      <option class="dropdown-item" value="rwandan">Rwandan</option>
      <option class="dropdown-item" value="saint lucian">Saint Lucian</option>
      <option class="dropdown-item" value="salvadoran">Salvadoran</option>
      <option class="dropdown-item" value="samoan">Samoan</option>
      <option class="dropdown-item" value="san marinese">San Marinese</option>
      <option class="dropdown-item" value="sao tomean">Sao Tomean</option>
      <option class="dropdown-item" value="saudi">Saudi</option>
      <option class="dropdown-item" value="scottish">Scottish</option>
      <option class="dropdown-item" value="senegalese">Senegalese</option>
      <option class="dropdown-item" value="serbian">Serbian</option>
      <option class="dropdown-item" value="seychellois">Seychellois</option>
      <option class="dropdown-item" value="sierra leonean">
        Sierra Leonean
      </option>
      <option class="dropdown-item" value="singaporean">Singaporean</option>
      <option class="dropdown-item" value="slovakian">Slovakian</option>
      <option class="dropdown-item" value="slovenian">Slovenian</option>
      <option class="dropdown-item" value="solomon islander">
        Solomon Islander
      </option>
      <option class="dropdown-item" value="somali">Somali</option>
      <option class="dropdown-item" value="south african">South African</option>
      <option class="dropdown-item" value="south korean">South Korean</option>
      <option class="dropdown-item" value="spanish">Spanish</option>
      <option class="dropdown-item" value="sri lankan">Sri Lankan</option>
      <option class="dropdown-item" value="sudanese">Sudanese</option>
      <option class="dropdown-item" value="surinamer">Surinamer</option>
      <option class="dropdown-item" value="swazi">Swazi</option>
      <option class="dropdown-item" value="swedish">Swedish</option>
      <option class="dropdown-item" value="swiss">Swiss</option>
      <option class="dropdown-item" value="syrian">Syrian</option>
      <option class="dropdown-item" value="taiwanese">Taiwanese</option>
      <option class="dropdown-item" value="tajik">Tajik</option>
      <option class="dropdown-item" value="tanzanian">Tanzanian</option>
      <option class="dropdown-item" value="thai">Thai</option>
      <option class="dropdown-item" value="togolese">Togolese</option>
      <option class="dropdown-item" value="tongan">Tongan</option>
      <option class="dropdown-item" value="trinidadian or tobagonian">
        Trinidadian or Tobagonian
      </option>
      <option class="dropdown-item" value="tunisian">Tunisian</option>
      <option class="dropdown-item" value="turkish">Turkish</option>
      <option class="dropdown-item" value="tuvaluan">Tuvaluan</option>
      <option class="dropdown-item" value="ugandan">Ugandan</option>
      <option class="dropdown-item" value="ukrainian">Ukrainian</option>
      <option class="dropdown-item" value="uruguayan">Uruguayan</option>
      <option class="dropdown-item" value="uzbekistani">Uzbekistani</option>
      <option class="dropdown-item" value="venezuelan">Venezuelan</option>
      <option class="dropdown-item" value="vietnamese">Vietnamese</option>
      <option class="dropdown-item" value="welsh">Welsh</option>
      <option class="dropdown-item" value="yemenite">Yemenite</option>
      <option class="dropdown-item" value="zambian">Zambian</option>
      <option class="dropdown-item" value="zimbabwean">Zimbabwean</option>
    </select>
    <cmp-required
      ref="ref_nationality"
      :p_value="d_value"
      :p_validations="['required']"
    />
  </div>
</template>

<script>
import c_required from "./cmp-required.vue";
export default {
  name: "cmp-nationality",
  components: {
    "cmp-required": c_required,
  },
  props: {
    p_nationality: {
      type: String,
    },
  },
  data() {
    return { d_value: null };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.p_nationality != null) {
        this.d_value = this.p_nationality.toLowerCase();
      }
    });
  },
  methods: {
    m_onChangeNationality(p_event) {
      this.$emit("e_change", p_event.target.value);
    },
    m_isValid() {
      return this.$refs.ref_nationality.m_isValid();
    },
  },
};
</script>
